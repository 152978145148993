.skeleton-block {
  position: relative;
  border: 2px dashed transparent;
  display: inline-flex;
  align-items: center;
}

.skeleton-block.hoverable:hover {
  border-color: #D3D5DB;
}
.skeleton-block.hoverable:hover .skeleton-block__buttons {
  visibility: visible;
  opacity: 1;
}

.skeleton-block__buttons {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: -38px;
  opacity: 0;
  visibility: hidden;
  z-index: 7;
}
.skeleton-block__buttons a {
  text-decoration: none;
}

.skeleton-block__change {
  display: flex;
  align-items: center;
  padding: 8px 30px 8px 8px;
  border-radius: 8px;
  background: #247CFF url("../img/skeleton/skeleton-gear.svg") no-repeat right 8px top 50%;
  color: #ffffff;
  text-decoration: none;
  transition: all 0.3s ease;
  white-space: nowrap;
  line-height: 1.2;
}
.skeleton-block__change:hover {
  background-color: #2F44FF;
  color: #ffffff;
  text-decoration: none;
}
.skeleton-block__change:active {
  background-color: #1337B6;
}
.skeleton-block__change:active svg {
  fill: #ffffff;
}

.skeleton-block__delete {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background: #FFE1E1;
  border-radius: 8px;
  margin-left: 4px;
  transition: all 0.3s ease;
}
.skeleton-block__delete svg {
  fill: #FF5959;
  transition: all 0.3s ease;
}
.skeleton-block__delete:hover {
  background: #FF5959;
}
.skeleton-block__delete:hover svg {
  fill: #FFE1E1;
}
.skeleton-block__delete:active {
  background: #FF2F2F;
}
.skeleton-block__delete:active svg {
  fill: #ffffff;
}

.skeleton-block__dropdown.dropdown-menu {
  padding: 16px;
  min-width: 220px;
  box-sizing: border-box;
}
.skeleton-block__dropdown.dropdown-menu ol {
  padding: 0;
  margin: 0;
  counter-reset: myCounter;
}
.skeleton-block__dropdown.dropdown-menu li {
  list-style: none;
  display: flex;
  line-height: 1.2;
}
.skeleton-block__dropdown.dropdown-menu li:not(:last-child) {
  margin-bottom: 16px;
}
.skeleton-block__dropdown.dropdown-menu li:before {
  counter-increment: myCounter;
  content: counter(myCounter);
  font-size: 8px;
  color: white;
  background: #D3D5DB;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-right: 8px;
  margin-top: 2px;
  min-width: 14px;
  max-width: 14px;
  height: 14px;
}

.btn-light-danger {
  background-color: #FFE1E1;
  color: #FF5959;
}
.btn-light-danger:hover {
  background-color: #FF5959;
  color: white;
}

.btn-light-success {
  background-color: #AFE2FF;
  color: #247CFF;
}
.btn-light-success:hover {
  background-color: #149CFF;
  color: white;
}